/**
 * This file contains custom filter functions for Tabulator
 */

export const customTabulatorFilters = {
    customEquals: (filterVal, rowVal) => {
        let customFilterVal = filterVal === 'No data' ? null : filterVal;
        if (customFilterVal !== null && typeof rowVal === 'number') {
            customFilterVal = Number(customFilterVal);
        }
        if (Array.isArray(rowVal)) {
            if (!rowVal.length) return false;
            return rowVal[0].value === customFilterVal;
        } else {
            return rowVal === customFilterVal;
        }
    },
    customIn: (filterVal, rowVal) => {
        const customFilterVal = filterVal.map(element => element === 'No data' ? null : element);
        if (Array.isArray(rowVal)) {
            if (!rowVal.length) return false;
            return customFilterVal.includes(rowVal[0].value);
        } else {
            return customFilterVal.includes(rowVal);
        }
    },
    // Filter to match students where they have at least one selected tag (used in myTags field)
    myTagFilter: (filterVal, rowVal) => {
        if (!Array.isArray(rowVal)) return false;
        if (!Array.isArray(filterVal)) return false;
        if (!rowVal.length) return false;
        if (!filterVal.length) return false;
        return filterVal.some(element => rowVal.includes(element));
    },

    tagsFilter: (filterVal, rowValue, rowData) => {
        //tags is an array containing logs of tags
        //take the most recent one at index 0 and look there

        //handle No Tags case  - exclusion
        if (!Array.isArray(filterVal)) return false;
        if (!filterVal.length) return false;

        return filterVal.some(tagID => tagID === 'No Tag' ? !rowValue.length : rowValue?.[0]?.value.includes(tagID));
    },
};
